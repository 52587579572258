<template>
    <voice-response-message v-if="messageBubble || hideEdits" :value="value"></voice-response-message>
    <div v-else class="d-flex flex-column">
        <v-fade-transition leave-absolute>
            <div v-if="showHintText" class="text-caption mb-4">{{selectionMessage}}</div>
        </v-fade-transition>
        <div class="d-flex flex-row audio-record flex-grow-1">
            <v-fade-transition hide-on-leave>
                <audio-recorder v-if="showAudio" v-model="answer" :audioUrl="answer"
                    :audioProperties="stepProperties" @toggleTextDisable="toggleDisableTextResponse"
                    :activeStep="activeStep"
                    :hide-recording-button="hideEdits"
                    :hide-remove-recording-button="hideRemoveRecording"
                    :showAvatar="hideEdits"
                    ref="audioRecorder"
                ></audio-recorder>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
                <div v-if="showTextInput || error"
                    class="d-flex flex-grow-1 flex-row">
                    <div v-if="showOR"
                        class="divider flex-column justify-center mx-3">
                        <v-divider class="divider-item" :vertical="true" :dark="dark"></v-divider>
                        <span class="divider-label" :style="{ color: contrastTextColor }">{{orDivider}}</span>
                        <v-divider class="divider-item" :vertical="true" :dark="dark"></v-divider>
                    </div>
                    <div class="d-flex flex-column justify-end" v-if="!textOptionSelected">
                        <div style="min-height: 24">&nbsp;</div>
                        <div class="d-flex align-center justify-center" style="width: 100px; height: 100px">
                            <v-btn fab elevation="0" :color="styles.dark ? '#ffffff46' : '#00000010'" width="80" height="80"
                                @click="handleTextOptionSelection">
                                <v-icon :style="{ color: contrastTextColor }">mdi-form-textbox</v-icon>
                            </v-btn>
                        </div>
                        <div class="text-caption mt-2 helper-text">
                            {{textInputPlaceholder}}
                        </div>
                    </div>
                    <div v-if="messageBubble" class="d-flex flex-grow-1">
                        <v-avatar size="44" :color="styles.dark?'#ffffff20':'#00000020'">
                            <v-icon>mdi-account</v-icon>
                        </v-avatar>
                        <v-card elevation="0" :color="styles.dark?'#ffffff20':'#00000020'" class="pa-3 ml-4">
                            {{ textInput }}
                        </v-card>
                    </div>
                    <v-textarea v-else v-show="textOptionSelected" v-model="textInput" clearable
                        clear-icon="mdi-close-circle" :disabled="this.permissions && disableTextResponse" outlined
                        :placeholder="textInputPlaceholder" rows="1" auto-grow :dark="dark" @focus="handleFocus" @focusin="textInputFocus=true"
                        @blur="handleTextInputBlur" :hide-details="!showCharactersCounter" validate-on-blur
                        class="desktop-input" ref="textInput" :rules="[rules.textInput]"
                        :messages="counterMessage"
                        :loading="!!(showCharactersCounter && progressValue )"
                    >
                        <template v-if="!followup" v-slot:progress>
                            <v-progress-linear
                                :value="progressValue"
                                :color="progressColor"
                                absolute
                                height="4"
                                rounded
                            ></v-progress-linear>
                        </template>
                    </v-textarea>
                    <v-btn :disabled="!textInput || (!followup && typeof validateTextInput(textInput) === 'string')" @click="handleSendText" v-if="textOptionSelected && activeStep.properties && activeStep.properties.enable_probing && !messageBubble" class="mx-2" width="56" height="56" :color="styles.button.background" elevation="0" >
                        <v-icon :color="styles.button.color">mdi-send</v-icon>
                    </v-btn>
                </div>
            </v-fade-transition>
        </div>
    </div>
</template>

<script>
import AudioPlayerControls from "../audio-recorder/AudioPlayerControls.vue"

import microphone from "@/assets/svg/microphone.svg";
import { mapGetters, mapActions, mapMutations } from "vuex";
import AudioRecorder from '../audio-recorder/AudioRecorder.vue';
import ExpandTransition from '../ExpandTransition.vue';
import * as amplitude from '@amplitude/analytics-browser';
import ProbeApi from "@/api/ProbeApi";
import TypingTextEffect from '../TypingTextEffect.vue';
import VoiceResponseMessage from './VoiceResponseMessage.vue';
import Handlebars from "handlebars";
import {get} from "lodash";


export default {
    name: "VoiceResponse",
    components: { AudioPlayerControls, AudioRecorder, ExpandTransition, TypingTextEffect, VoiceResponseMessage },
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        contrastTextColor: {
            required: true,
        },
        dark: {
            required: true
        },
        followup:{
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            disableTextResponse: false,
            stream: null,
            recorder: null,
            audio: null,
            microphone,
            error: null,
            textOptionSelected: false,
            textInputFocus: false,
            rules: {
                textInput: this.validateTextInput,
            },
            messageBubble: this.activeStep.properties?.enable_probing && this.value && this.value.type === 'text' && !!((this.$store?.state?.form?.dialogues[this.activeStep.id] || []).length),
        };
    },
    computed: {
        selectionMessage(){
            return  this.customLabels.hint_text || this.$t('form.voiceResponseSelectionMessage')
        },
        orDivider(){
            return  this.customLabels.or_divider || this.$t('form.voiceResponseSelectionOrDivider')
        },
        textInputPlaceholder(){
            return this.customLabels.text_input_placeholder || this.$t('form.voiceResponseTextInputPlaceholder')
        },
        answer: {
            get: function () {
                if (this.value && this.value.type === 'audio') {
                    return this.value.url || this.value;
                }
                return null
            },
            set: function (value) {
                if (value) {
                    if(this.activeStep.properties?.enable_probing && value.valid===true) {
                        this.handleProbing({ type: "audio", ...value })
                    }
                    this.$emit("input", { type: "audio", ...value });
                } else {
                    this.$emit("input", null);
                }
                this.$emit("change");
            },
        },
        textInput: {
            get: function () {
                return this.value && this.value.type === 'text' ? this.value.value : "";
            },
            set: function (value) {
                this.$emit("input", value? {answer: { type: "text", value }, valid: this.validateTextInput(value)}:null);
                this.$emit("change");
            },
        },
        ...mapGetters({
            survey: 'form/survey',
            styles: 'form/styles',
            recording: "audio/recording",
            loading: "audio/loading",
            streamInitLoading: "audio/streamInitLoading",
            permissions: 'audio/permissions',
            permissionsError: 'audio/permissionsError',
            uploadingRecording: 'audio/uploadingRecording',
            interpolationData: 'form/interpolationData',
        }),
        showTextInput: function() {
            let isTextInputVisible = false;

            // Check if there is a permissions error
            if (this.permissionsError === true && this.survey?.settings?.text_input_on_mic_error !== false) {
                isTextInputVisible = true;
            }
            
            // Check if permissions are not granted
            else if (this.permissions === false && this.survey?.settings?.text_input_on_mic_error !== false) {
                isTextInputVisible = true;
            }
            
            // Check if text input is enabled on the active step
            else if (this.activeStep && this.activeStep.enable_text_input) {
                isTextInputVisible = true;
            }
            
            // Text input is not visible if there is an ongoing answer or recording
            if (this.answer || this.recording || this.uploadingRecording) {
                isTextInputVisible = false;
            }

            // If text input is already there. Scenario if previously there was a mic error and response was restored via partial response  
            if(this.textInput){
                isTextInputVisible = true
            }

            return isTextInputVisible;
        },
        showAudio: function () {
            return !this.textInputFocus && !this.textInput
        },
        showOR() {
            return !this.textInput && !this.textInputFocus && !this.answer
        },
        showError() {
            return !this.recording
        },
        showHintText() {
            if (!this.textOptionSelected && this.activeStep.enable_text_input && !this.answer && !this.recording && !this.uploadingRecording && !this.value && !this.textInput) {
                return true
            }
            return false
        },
        minNumberOfCharacters(){
            return this.activeStep?.properties?.minNumberOfCharacters || 0
        },
        showCharactersCounter(){
            return !!(!this.followup && this.minNumberOfCharacters && (this.textInputFocus || this.textInput))
        },
        counterMessage(){
            if(this.showCharactersCounter){
                const minChars = this.activeStep.properties.minNumberOfCharacters;
                const charCount = this.textInput.trim().length;

                if(!this.textInput.trim().length){
                    return this.$t('form.voiceResponseTextInputCharacterCounterMinMessage', { min: minChars });
                }
                return this.$t('form.voiceResponseTextInputCharacterCounterCurrentNumberMessage', { count: charCount, min: minChars });

            }
            return ''
        },

        progressValue(){
            if(this.showCharactersCounter && this.textInput && this.textInput.trim()){
                return  this.textInput.trim().length/this.activeStep.properties.minNumberOfCharacters *100
            }
            return 0
        },
        progressColor(){
            if(this.progressValue < 50){
                return this.dark?'red lighten-3':'red'
            }

            if(this.progressValue < 75){
                return this.dark?'orange lighten-3':'orange'
            }

            if(this.progressValue < 100){
                return this.dark?'yellow lighten-3':'yellow'
            }

            return this.dark?'green lighten-3':'green'
        },
        messages(){
            return this.$store?.state?.form?.dialogues[this.activeStep.id] || []
        },
        hideEdits(){
            if(this.activeStep?.properties?.enable_probing && this.value && this.value.valid===true){
                if(this.value.type === 'text'){
                    return false
                }
                return true
            }
            return false
        },
        hideRemoveRecording(){
            if(this.activeStep?.properties?.prevent_recording_deletion || this.hideEdits){
                return true
            }
            return false
        },
        startRecordingOnPageLoad(){
            return this.activeStep?.properties?.start_recording_on_page_load === true
        },
        stepProperties(){
            if(this.activeStep?.properties?.enable_probing && this.messages && this.messages.length){
                return {
                    ...this.activeStep.properties,
                    maxRecordTime: 60 * 2.5,
                    minRecordTime: 0,
                }
            }

            const data = {
                ...this.activeStep.properties
            }

            try {
                const maxAllowedRecordTime = this.survey?.features?.max_audio_response_time;

                if (this.activeStep?.properties?.max_variable) {
                    this.updateRecordTime(
                        this.activeStep.properties.max_variable,
                        false, // isMin
                        data,
                        this.interpolationData,
                        maxAllowedRecordTime
                    );
                }

                if (this.activeStep?.properties?.min_variable) {
                    this.updateRecordTime(
                        this.activeStep.properties.min_variable,
                        true, // isMin
                        data,
                        this.interpolationData,
                        maxAllowedRecordTime
                    );
                }
            } catch (error) {
                console.error('Error setting min and max record time:', error);
            }

            return data;
        },
        customLabels(){
            if(this.activeStep?.properties?.custom_labels){
                return this.activeStep.properties.custom_labels
            }

            return {}
        }
    },
    methods: {
        ...mapActions({
            stopRecording: "audio/stopRecording",
            startRecording: "audio/startRecording",
            startLoading: "audio/startLoading",
            stopLoading: "audio/stopLoading",
            startStreamInitLoading: "audio/startStreamInitLoading",
            stopStreamInitLoading: "audio/stopStreamInitLoading",
        }),
        ...mapMutations({
            addMessage: "form/addMessage",
            updateLastMessage: "form/updateLastMessage",
        }),
        // Virtual keyboard pushes the HTML page up in iPhone
        handleFocus() {

            amplitude.track('text_option_selected');

            // scroll to top
            if (/iPhone/i.test(navigator.userAgent)) {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 300);
            }
        },
        toggleDisableTextResponse(value) {
            this.disableTextResponse = value
        },
        handleTextOptionSelection() {
            this.textOptionSelected = true
            setTimeout(() => this.$refs?.textInput?.focus(), 10);
        },
        handleTextInputBlur() {
            if (!this.textInput) {
                this.textOptionSelected = false
            }
            this.textInputFocus = false
        },
        handleSendText(){
            if(this.textInput && this.textInput.trim().length){
                this.messageBubble = true
                this.handleProbing(this.value, true)
            }
        },
        validateTextInput(value) {
            if (this.showCharactersCounter) {
                const minChars = this.activeStep?.properties?.minNumberOfCharacters;
                const charCount = value.trim().length;

                return !value || charCount >= minChars || this.$t('form.voiceResponseTextInputCharacterValidationMessage', { count: charCount, min: minChars });
            }
            return true;
        },
        async handleProbing(value, isText=false){
            try {
                let question, goal;

                try {
                    const compiledQuestion = this.activeStep?.text ? Handlebars.compile(this.activeStep.text) : null;
                    question = compiledQuestion ? compiledQuestion(this.interpolationData) : this.activeStep?.text;
                } catch (error) {
                    console.error("Error compiling question:", error);
                    question = this.activeStep?.text;
                }

                try {
                    const compiledGoal = this.activeStep?.properties?.goal ? Handlebars.compile(this.activeStep.properties.goal) : null;
                    goal = compiledGoal ? compiledGoal(this.interpolationData) : this.activeStep?.properties?.goal;
                } catch (error) {
                    console.error("Error compiling goal:", error);
                    goal = this.activeStep?.properties?.goal;
                }

                const messages = [
                    {
                        "role": "assistant",
                        "content": JSON.stringify({
                            "original_question": question,
                            "goal": goal,
                        })
                    }
                ];

                if(this.messages){
                    this.messages.map(message => {
                        messages.push({
                            'role': 'user',
                            'content': JSON.stringify({
                                'transcription': message.transcription.text,
                            })
                        })

                        messages.push({
                            'role': 'assistant',
                            'content': JSON.stringify({
                                'sufficient': message.sufficient,
                                'followup': message.followup,
                            })
                        })
                    })
                }
                this.addMessage({
                    questionId: this.activeStep.id,
                    message: {
                        answer: null,
                        transcription: null,
                        followup: null,
                        sufficient: null,
                        loading: true,
                    }
                })

                let probeRes = null
                const isLastFollowup = this.activeStep.properties.max_probes && this.messages.length > this.activeStep.properties.max_probes
                if(isText){
                    probeRes = await ProbeApi.probe({
                        surveyId: this.survey.id, 
                        audioPath: null, 
                        messages, 
                        text: value.value, 
                        excludeFollowUp: isLastFollowup,
                        vocabulary: null,
                        language: this.survey?.settings?.language || 'en'
                    })
                }else{
                    probeRes = await ProbeApi.probe({
                        surveyId: this.survey.id,
                        audioPath: value.path,
                        messages, 
                        text: null,
                        excludeFollowUp: isLastFollowup,
                        vocabulary: this.activeStep?.properties?.vocabulary || null,
                        language: this.survey?.language || 'en'
                    })
                }


                if(isLastFollowup){
                    return this.updateLastMessage({
                        questionId: this.activeStep.id,
                        message: {
                            answer: value,
                            transcription: probeRes?.data?.transcription,
                            followup: '',
                            sufficient: true,
                            timestamp: new Date().getTime()/1000,
                        }
                    })
                }else{
                    this.updateLastMessage({
                        questionId: this.activeStep.id,
                        message: {
                            answer: value,
                            transcription: probeRes?.data?.transcription,
                            followup: probeRes?.data?.probe?.followup || '',
                            sufficient: probeRes?.data?.probe?.sufficient,
                            timestamp: new Date().getTime()/1000,
                        }
                    })
                }

            } catch (error) {
                console.error('error', error)
                // if last message is loading, we need to change it to sufficient true not to block the respondent
                if(this.messages && this.messages.length){
                    const lastMessage = this.messages[this.messages.length - 1]
                    if(lastMessage.loading){
                        this.updateLastMessage({
                            questionId: this.activeStep.id,
                            message: {
                                answer: value,
                                transcription: null,
                                followup: null,
                                sufficient: true,
                                timestamp: new Date().getTime()/1000,
                                failed: true
                            }
                        })
                    }
                }

            }
        },
        parseValue(value) {
            if (typeof value === 'number') return value;
            if (typeof value === 'string') {
                const parsedValue = Number(value);
                return isNaN(parsedValue) ? null : parsedValue;
            }
            return null;
        },// Helper function to update record time
        updateRecordTime(variablePath, isMin, data, interpolationData, maxAllowedRecordTime) {
            const value = get(interpolationData, variablePath);
            const parsedValue = this.parseValue(value);
            if (parsedValue !== null && parsedValue >= 0) {
                if (isMin) {
                    const maxRecordTime = data.maxRecordTime || maxAllowedRecordTime;
                    if (parsedValue <= maxRecordTime) {
                        data.minRecordTime = parsedValue;
                    }
                } else {
                    data.maxRecordTime = Math.min(parsedValue, maxAllowedRecordTime);
                }
            }
        }
    },
    mounted(){
        if(this.textInput){
            this.textOptionSelected=true
        }
        const resumeDialog = document.getElementById('vf-resume-form-dialog') || null
        if(this.startRecordingOnPageLoad && !resumeDialog && !this.value){
            this.$refs?.audioRecorder?.handleRecordPress()
        }
    },
    watch: {
        textInput: {
            handler: function (value) {
                if (value) {
                    this.textOptionSelected = true
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.text-label {
    padding-top: 8px;
    font-size: 16px;
    text-align: center;
}

.desktop-input {
    align-items: center;
}

.divider {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;

    &-label {
        margin-left: 8px;
        margin-right: 8px;
    }
}

.divider::v-deep .v-divider--vertical {
    align-self: unset;
    min-height: unset;
}

.audio-record {
    justify-items: center;
    // width: 100%;
    // min-height: 170px;

    .audio {
        // width: 100%;
    }
}

.helper-text {
    opacity: 0.7;
    text-align: center;
}
</style>
