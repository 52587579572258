<template>
    <div class="mt-4">
        <iframe
            v-if="isIframe"
            ref="videoIframe"
            :height="height"
            v-resize="onResize"
            frameborder="0" 
            :srcdoc="sourceDoc"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            style="width: 100%; max-width: 560px;"
        ></iframe>
        <iframe
            v-else
            ref="videoIframe"
            :height="height"
            v-resize="onResize"
            :src="videoSource"
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            style="width: 100%; max-width: 560px;"
        >
        </iframe>
    </div>
</template>
<script>
import { mapGetters} from 'vuex';
import Handlebars from 'handlebars';

export default {
    name: 'VideoAttachment',
    props: {
        interpolationData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            height: null,
        }
    },
    computed: {
        ...mapGetters({
            activeStep: "form/activeStep",
        }),
        videoSource() {
            if(this.activeStep && this.activeStep.video_source) {
                // eslint-disable-next-line
                const regExp = /^https?\:\/\/.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                const match = this.activeStep.video_source.match(regExp);
                const uniqueId = (match && match[2].length === 11) ? match[2] : false;
                // eslint-disable-next-line
                if (!!uniqueId) {
                    return `https://www.youtube.com/embed/${uniqueId}?enablejsapi=1`;
                }
                return '';
            }
            return '';
        },
        isIframe() {
            if(this.activeStep && this.activeStep.video_source) {
                // eslint-disable-next-line
                const regex = /^<iframe.*>.*<\/iframe>/g;
                const match = this.activeStep.video_source.match(regex);
                return !!match;
            }
            return false;
        },
        iframeSrc() {
            let source = this.activeStep?.video_source;
            if(this.isIframe){
                // eslint-disable-next-line
                source = this.activeStep.video_source.replace(/width=\"\d*(px|%)?\"/, '');
                // eslint-disable-next-line
                source = source.replace(/height=\"\d*(px|%)?\"/, '');
                source = source.replace(/<iframe /, '<iframe width="100%" height="100%" ');
            }
            const template = Handlebars.compile(source, {noEscape: true})

            return template(this.interpolationData);
        },
        sourceDoc() {
            return `<html style="margin: 0; padding: 0; height: 100%; overflow: hidden;">
                        <body style="margin: 0; padding: 0; height: 100%; overflow: hidden;">
                            ${this.iframeSrc}
                        </body>
                    </html>`;
        },
    },
    mounted() {
        this.onResize();
    },
    methods: {
        onResize() {
            const rectangle = this.$refs.videoIframe.getBoundingClientRect();
            if(rectangle.width) {
                this.height = (315/560)*rectangle.width;
            }
        }
    },
}
</script>

<style scoped>
    .iframe-class {
        width: 100%;
        max-width: 560px;
    }
</style>